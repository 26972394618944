body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
}

@media (min-width: 768px){
  .container {
    max-width: 900px;
  }
}
@media (min-width: 576px){
  .container {
    max-width: none;
  }
}

/* TEXT */
h1 {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 3px solid black;
  padding-bottom: 10px;
  margin-top: 20px;
  margin-bottom: 50px;
}

h2 {
  text-transform: uppercase;
  color: #0D66A8;
}

h3 {
  font-size: 20px;
  color: #0D66A8;
  font-weight: bold;
}

h4 {
  font-size: 18px;
  color: #627590;
  font-weight: bold;
}

.text-blue{color: #024089 !important;}
.text-blue-alt{color: #00B4D5 !important;}
.text-red{color: #BB3234 !important;}
.text-green{color: #BFD008 !important;}

.dashTitle {
  font-size: 18px;
}
.dashTitle span {
  display: inline-block;
}
.dashTitle span:before,
.dashTitle span:after {
  content: "";
  border-bottom: 3px solid white;
  display: inline-block;
  width: 30px;
  margin-bottom: 5px;
}
@media screen and (min-width: 692px) and (max-width: 1079px){
  .dashTitle span:before,
  .dashTitle span:after {
    width: 18px;
  }
}

.dashTitleSmall span:before,
.dashTitleSmall span:after {width: 10px;}
.dashTitle span:before {margin-right: 10px;}
.dashTitle span:after {margin-left: 10px;}

.text-asterisk {
  font-size: 12px;
  font-weight: bold;
}

.font-18{font-size: 18px}

table {
  font-size: 13px;
}
.react-bs-table table td, .react-bs-table table th{
  white-space: unset!important;
}
/* MENU */
#mainHeader a{
  font-size: 14px;
  line-height: 15px;
}
#mainHeader .navbar{
  justify-content: start;
}
#mainHeader .nav-item{
  /* margin-right: 20px; */
}
#mainHeader .active{
  background-color: #3b92cc;
  border-radius: 10px;
}

#containerRightSide {
  /* position: absolute;
  right: 0;
  min-width: 330px; */
}
.v-hr{
  border: 1px solid white;
  height: 55%;
  margin: 10px 5px;
}

.menu {
  text-align: center;
  color: white;
  padding: 20px 8px;
}
.menu-blue{background-color: #0D66A8;}
.menu-red{background-color: #BB3234;}

.linkMenu {
  background-color: white !important;
  color: #0D66A8;
  padding: 5px 10px !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
}
.linkMenu .fas {
  margin-right: 15px;
  float:left;
  margin-top:4px;
}

#menuConnected li{
  margin-bottom: 15px;
}
#menuConnected li a{
  display: block;
  width: 90%;
  margin: auto;
}

/* BUTTONS  */
.btn-lqo {
  border-radius: 0;
  font-size: 14px;
  padding-left: 50px;
  padding-right: 50px;
}
.btn-lqo-primary {
  color: #fff;
  background-color: #BB3234 !important;
  border-color: #BB3234;
}
.btn-lqo-primary:hover {color: rgb(255, 182, 183);}

.btn-lqo-secondary {
  color: #fff;
  background-color: #0D66A8 !important;
  border-color: #0D66A8;
}
.btn-lqo-secondary:hover {color: rgb(191, 225, 252);}

.btn-lqo-success {
  color: #fff;
  background-color: #11a80d  !important;
  border-color: #11a80d ;
}
.btn-lqo-success:hover {color: white;}

/* FORM */
label {
  font-weight: bold;
  text-align: right;
}
@media screen and (max-width: 600px) {
  label {
    text-align:left;
  }
}
@media screen and (max-width: 770px) {
  #containerRightSide {
    margin-top:20px;
  }
}
.form-control::-webkit-input-placeholder { color: #D4D4D4; }  /* WebKit, Blink, Edge */
.form-control:-moz-placeholder { color: #D4D4D4; }  /* Mozilla Firefox 4 to 18 */
.form-control::-moz-placeholder { color: #D4D4D4; }  /* Mozilla Firefox 19+ */
.form-control:-ms-input-placeholder { color: #D4D4D4; }  /* Internet Explorer 10-11 */
.form-control::-ms-input-placeholder { color: #D4D4D4; }  /* Microsoft Edge */

/* ANIMATION */
.animated {
  animation-duration: 1s; 
}

.animated.infinite {
  animation-iteration-count: infinite; 
}

.animated.hinge {
  animation-duration: 2s; 
}

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; 
}

/* HOME */
.contentBox {
  margin-top: 20px;
  background: #efefef;
}
.contentBox h2 {
  color: white;
  background: #0d66a8;
  margin: 0;
  padding: 20px 10px;
  text-transform: uppercase;
  font-size: 18px;
  padding-left: 130px;
  background-repeat: no-repeat;
  border: 2px solid #0d66a8;
}
h2.lastLegal {background-image:url("/img/lastLegal.png");}
h2.publicMarket {background-image:url("/img/publicMarket.png");}
h2.elecSign {background-image:url("/img/elecSign.png");}

#home p {
  font-size: 14px;
  line-height: 17px;
}

.img-sub-title {
  color: white;
  margin-top: -30px;
  position: relative;
  font-weight: bold;
}
.img-sub-title span{
  background: #0EA1D0;
  border-radius: 20px;
  padding: 5px 25px;
  min-width: 170px;
  display: inline-block;
}

/* USER DETAIL */
.offer-container {
  color: #0d66a8;
  border: 3px solid;
  padding: 10px;
  text-align: center;
  margin: 2% auto;
  cursor: pointer;
  font-weight: bold;
  font-size: 21px;
}

.offer-container .offer-item, 
.modep-container .modep-item {
  display: block;
}
.offer-item.offer-duration {
    font-size: 34px;
}
.offer-container.offer-selected {
    background: #0d66a8;
    color:white;    
    border: 3px solid #01487d;
}
.modep-container {
  border: 3px solid;
  padding: 10px;
  text-align: center;
  margin: 2% auto;
  cursor: pointer;
  color:#bc3133;
  background:white;
  font-size: 24px;
  font-weight: bold;
}
.modep-container.modep-selected {
  color:white;
  background:#bc3133;
  border: 3px solid #901516;
}
.modep-item.modep-label {
  font-size: 14px;
}

.current-offer-container {
  border: 3px solid #0DA80D;
  padding: 10px;
  text-align: center;
  color:#0DA80D;
  font-size: 34px;
  font-weight: bold;
}

.current-offer-disabled {
  border: 3px solid #bc3133;
  color: #bc3133;
}
.current-offer-container .current-offer-duration {
  font-size: 21px;
  font-weight: normal;
}

.preview {
  font-size: 14px;  
  padding-top:20px;
}
.preview h4 {
  text-align: center;
  color: #bb3234;
}
.preview .form-group {
  margin-bottom: 8px;
}
.preview .col-form-label {
  padding:0;
}
.content-block {
  border: 1px solid rgb(187, 50, 52);
  padding-top:10px;
  padding-bottom: 10px;
}

/* PUBLIC MARKET */
#public-market .title-icon{
  position: relative;
  z-index: 10;
  margin-top: -15px;
}
.prices li{
  padding: 10px;
  border-left: solid 2px #00B4D5;
  border-right: solid 2px #00B4D5;
  border-bottom: solid 2px #00B4D5;
}

/* FRAME */
.frame-blue {
  padding: 20px;
  background-color: #024089;
}
.frame-blue .title{
  color: #BFD008;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px;
}
.frame-blue .list-unstyled{
  font-size: 15px;
}
.frame-blue .list-unstyled .text-green{
  font-size: 25px;
  line-height: 18px;
}

.bg-blue{background-color: #0D66A8 !important;}
.bg-blue-alt{background-color: #00B4D5 !important;}
.bg-red{background-color: #BB3234 !important;}
.bg-green{background-color: #BFD008 !important;}

/* TOOLS */
.num-point {
  background-color: #E30613;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 23px;
  font-weight: bold;
  color: white;
  margin: 5px auto;
}